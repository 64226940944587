<template>
   <div class="container card">
            <div class="table-wrapper">
               <div class="table-title">
                    <div class="row">
                    <div class="col-sm-6">
                        <h2>All <b>Products</b></h2>
                    </div>
                    
                </div>
               </div>
            </div>
            <table class="table table-striped table-hover display" id="table_id">
                <thead>
                    <tr>
                        <th>Brand Name</th>
                        <th>Generic Name</th>
                        <th>Global Trade Item Number</th>
                        <th>Universal Prodct Code</th>
                        <th>Manufacturing Countries</th>
                        <th>Manufacturing Locations</th>
                        <th>Certification Number</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, key) in products" :key="key">
                        <td>{{product.brand_name}}</td>
                        <td>{{product.generic_name}}</td>
                        <td>{{product.gtin}}</td>
                        <td>{{product.upc}}</td>
                        <td>
                            <p v-for="(country, key) in product.manufacturing_countries" :key="key">
                                {{country}}
                            </p>
                        </td>
                        <td>
                            <p v-for="(location, key) in product.manufacturing_locations" :key="key">
                                {{location}}
                            </p>
                        </td>
                        <td>{{product.certification_number[1]}}</td>
                        <td>
                        <a href="" class="btn btn-success" data-toggle="modal"><i class="fa  fa-eye" aria-hidden="true" data-toggle="tooltip" title="Edit"></i></a>
                        </td>
                    </tr>  
                </tbody>
            </table>
        </div>    
</template>

<script>
import {mapState} from 'vuex'

    export default {
        computed: {
           products() {
               return this.$store.getters.products
           }
        },
        created(){
            this.$store.dispatch('fetchAllProducts')
            // console.log(this.products)
        }
       
    }
</script>

<style  scoped>
.table-wrapper{
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title{
    padding-bottom: 15px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    margin: 5px 0 0;
    font-size: 24px;
}

/* .pagination{
    float: right;
    margin: 0 0 5px;
} */

/* .pagination li a{
    border: none;
    font-size: 14px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 1px !important;
    text-align: center;
    padding: 0 6px;
} */
</style>